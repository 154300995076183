//@ts-ignore
import style from "./headerModal.module.css";
import { OperationSuccessIcon, WarningTriangleIcon } from "@Models/consts/icons";

const HeaderModal = ({ title, content, icon = "warning" }) => {
  return (
    <main className={style.modal}>
      <strong className={style.titleModal}>{title}</strong>
      {icon === "warning" && <img className={style.icon} src={WarningTriangleIcon} alt="warn" />}
      {icon === "success" && <img className={style.icon} src={OperationSuccessIcon} alt="success" />}
      <p className={style.contentModal}>{content}</p>
    </main>
  );
};

export default HeaderModal;

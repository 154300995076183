import convertExpirationDate, { NO_DATE } from "@Adapter/dateAdapter/convertExpirationDateAdapter";
import { ExpirationDate } from "@Models/types/dates";
import React, { Fragment } from "react";
//@ts-ignore
import style from "./style/dueDate.module.css";

interface IRucDueDateProps {
  expirationDate: string;
  index: number;
}

const RucDueDate: React.SFC<IRucDueDateProps> = (props) => {
  const { expirationDate } = props;
  const date = convertExpirationDate(expirationDate);

  if (date === NO_DATE) return <p>{NO_DATE}</p>;

  return (
    <p className={style.dueDate} id={"billing-rucList-item-" + props.index + "-text-rucDueDate"}>
      {date.monthString} {date.dayString} / {date.yearString}
    </p>
  );
};

export default RucDueDate;

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// @ts-ignore
import { NotFoundPage, ProtectedRoute } from "@viuti/recursos";
import { BILLING_ROUTE } from "@Models/consts/routes";
import ListRucsPage from "@Pages/listRucs/ListRucsPage";
import NewRucsPage from "@Pages/newRuc/NewRucPage";
import EditRucPage from "@Pages/editRuc/EditRucPage";
import { CREATE_CERTIFICATES_UUID, MODIFY_CERTIFICATES_UUID } from "@Models/consts/securityAccess";

// Route page of the application
export default function App() {
  return (
    <Router>
      <Routes>
        <Route path={BILLING_ROUTE.ListRucs} element={<ListRucsPage />} />
        <Route
          path={BILLING_ROUTE.newRucs}
          element={<ProtectedRoute accessKeyModule={CREATE_CERTIFICATES_UUID} element={<NewRucsPage />} />}
        />
        <Route
          path={`${BILLING_ROUTE.editRucs}/:idCertificate`}
          element={<ProtectedRoute accessKeyModule={MODIFY_CERTIFICATES_UUID} element={<EditRucPage />} />}
        />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Router>
  );
}

import { BILLING_ROUTE } from "@Models/consts/routes";
import { CREATE_CERTIFICATES_UUID } from "./securityAccess";
// @ts-ignore
import { validateModuleAccess } from "@viuti/recursos";

const TITLE_HEADER = "Facturación electrónica";
const BUTTON_TEXT = "Agregar RUC autorizado";

export const headerProps = (navigate) => ({
  title: TITLE_HEADER,
  buttonProps: {
    textBttn: BUTTON_TEXT,
    isPrimary: true,
    handleClick: () => validateModuleAccess(CREATE_CERTIFICATES_UUID) && navigate(BILLING_ROUTE.newRucs),
  },
});

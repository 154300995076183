import React, { Dispatch } from "react";
//@ts-ignore
import styled from "./style/rucItem.module.css";
import { IRuc } from "@Models/interfaces/ruc";
import { RucActions, RucDueDate, RucName, RucStatus } from "./components";
import RucStatusMsg from "./components/RucStatusMsg/RucStatusMsg";
import { IModalProps } from "@Models/interfaces/modals";

interface IRucItemProps {
  rucData: IRuc;
  refreshRucsAction: () => Promise<void>;
  setModalProps: Dispatch<IModalProps>;
  setShowModal: Dispatch<boolean>;
  setIsDeleting: Dispatch<boolean>;
  index: number;
}

const RucItem: React.SFC<IRucItemProps> = (props) => {
  const { rucData, refreshRucsAction, setModalProps, setShowModal, setIsDeleting } = props;
  const { businessName, ruc, nameState, expirationDate, idCertificate } = rucData;

  return (
    <article className={styled.itemsRucs__article} id={"billing-rucList-item-" + props.index}>
      <RucName businessName={businessName} ruc={ruc} index={props.index} />
      <RucStatus nameState={nameState} index={props.index} />
      <RucDueDate expirationDate={expirationDate} index={props.index} />
      <RucStatusMsg expirationDate={expirationDate} />
      <RucActions
        idCertificate={idCertificate}
        refreshRucsAction={refreshRucsAction}
        setModalProps={setModalProps}
        setShowModal={setShowModal}
        setIsDeleting={setIsDeleting}
        index={props.index}
      />
    </article>
  );
};

export default RucItem;

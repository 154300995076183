import { RucActions, RucDueDate, RucName, RucStatus } from "@Components/ui/atoms/RucRowItem/components/RucItem/components";
import RucStatusMsg from "@Components/ui/atoms/RucRowItem/components/RucItem/components/RucStatusMsg/RucStatusMsg";
interface DataTable {
  id: string | number;
  header: any[];
  body: { item1: any; item2: any }[];
}

export const prepareDataTableForMobile = (ruc, index, screenWidth, props): DataTable => {
  let headerTitles = [];
  const { refreshRucsAction, setModalProps, setShowModal, setIsDeleting } = props;
  if (screenWidth < 900) {
    headerTitles = [
      <RucName businessName={ruc.businessName} ruc={ruc.ruc} index={index} />,
      <RucStatus nameState={ruc.nameState} index={index} />,
      <RucDueDate expirationDate={ruc.expirationDate} index={index} />,
      <RucStatusMsg expirationDate={ruc.expirationDate} />,
    ];
  }
  if (screenWidth < 700) {
    headerTitles = [
      <RucName businessName={ruc.businessName} ruc={ruc.ruc} index={index} />,
      <RucStatus nameState={ruc.nameState} index={index} />,
      <RucDueDate expirationDate={ruc.expirationDate} index={index} />,
    ];
  }
  if (screenWidth < 500) {
    headerTitles = [
      <RucName businessName={ruc.businessName} ruc={ruc.ruc} index={index} />,
      <RucStatus nameState={ruc.nameState} index={index} />,
    ];
  }
  let body = [
    {
      item1: <p>Nombre del negocio</p>,
      item2: <p>{ruc.businessName}</p>,
    },
    {
      item1: <p>RUC</p>,
      item2: <p>{ruc.ruc}</p>,
    },
    {
      item1: <p>Estado</p>,
      item2: <p>{ruc.nameState}</p>,
    },
    {
      item1: <p>Fecha de vencimiento</p>,
      item2: <p>{ruc.expirationDate}</p>,
    },
    {
      item1: <p>Estado</p>,
      item2: (
        <p>
          <RucStatusMsg expirationDate={ruc.expirationDate} />
        </p>
      ),
    },
    {
      item1: <p>Opciones</p>,
      item2: (
        <RucActions
          idCertificate={ruc.idCertificate}
          refreshRucsAction={refreshRucsAction}
          setModalProps={setModalProps}
          setShowModal={setShowModal}
          setIsDeleting={setIsDeleting}
          index={props.index}
        />
      ),
    },
  ];

  const dataTable = {
    id: index,
    header: headerTitles,
    body: body,
  };
  return dataTable;
};

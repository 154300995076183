import React, { Dispatch, useState } from "react";
import { IModalProps } from "@Models/interfaces/modals";
import { IRuc } from "@Models/interfaces/ruc";
import DeleteRucRowItem from "./components/DeleteRucRowItem/DeleteRucRowItem";
import RucItem from "./components/RucItem/RucItem";

interface IRucRowItemProps {
  rucData: IRuc;
  refreshRucsAction: any;
  setModalProps: Dispatch<IModalProps>;
  setShowModal: Dispatch<boolean>;
  index: number;
}

const RucRowItem: React.SFC<IRucRowItemProps> = (props) => {
  const [isDeleting, setIsDeleting] = useState(false);

  if (isDeleting) return <DeleteRucRowItem />;

  return <RucItem {...props} setIsDeleting={setIsDeleting} />;
};

export default RucRowItem;

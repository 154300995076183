// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.judiXoRwd9MsPFvMuXQ6 {
  display: grid;
  grid-template-columns: auto auto;
  gap: 24px;
  margin-top: 46px;
  @media screen and (width < 768px) {
    grid-template-columns: auto;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/newRuc/components/FieldsWrapper/style/fieldsWrapper.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,gCAAgC;EAChC,SAAS;EACT,gBAAgB;EAChB;IACE,2BAA2B;EAC7B;AACF","sourcesContent":[".newRucsPage__Section {\n  display: grid;\n  grid-template-columns: auto auto;\n  gap: 24px;\n  margin-top: 46px;\n  @media screen and (width < 768px) {\n    grid-template-columns: auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"newRucsPage__Section": `judiXoRwd9MsPFvMuXQ6`
};
export default ___CSS_LOADER_EXPORT___;

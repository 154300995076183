import { IRuc } from "@Models/interfaces/ruc";
import { useEffect, useState } from "react";

interface IState {
  rucValue: string | null;
  businessName: string | null;
  certificateDate: string | null;
  certificatePassword: string | null;
}

const useCompleteData = ({ rucValue, businessName, certificateDate, certificatePassword }, setGlobalRucData): IRuc => {
  const [rucData, setRucData] = useState<IState>({
    rucValue,
    businessName,
    certificateDate,
    certificatePassword,
  });

  useEffect(() => {
    const newRucData = {
      rucValue,
      businessName,
      certificateDate,
      certificatePassword,
    };
    setRucData(newRucData);
  }, [rucValue, businessName, certificateDate, certificatePassword]);

  useEffect(() => {
    const newRucData = {
      rucValue: "",
      businessName: "",
      certificateDate: "",
      certificatePassword,
    };
    setRucData(newRucData);
  }, [certificatePassword]);

  useEffect(() => {
    setGlobalRucData({
      ruc: rucData.rucValue,
      businessName: rucData.businessName,
      expirationDate: rucData.certificateDate,
      claveCertificado: rucData.certificatePassword,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rucData]);

  return {
    ruc: rucData.rucValue,
    businessName: rucData.businessName,
    expirationDate: rucData.certificateDate,
    claveCertificado: rucData.certificatePassword,
  };
};

export default useCompleteData;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.crVfWsUJn_Wmy2dabPMt {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;
  max-height: 100%;
  overflow-y: auto;
}
`, "",{"version":3,"sources":["webpack://./src/pages/listRucs/views/IsRucsToShow/style/isRucsToShow.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;AAClB","sourcesContent":[".itemsRucs__section {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  margin-top: 24px;\n  max-height: 100%;\n  overflow-y: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"itemsRucs__section": `crVfWsUJn_Wmy2dabPMt`
};
export default ___CSS_LOADER_EXPORT___;

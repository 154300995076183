import { GET_RUCS_BY_ID } from "@Models/fetchUrls/listRucUrl";
import axios, { AxiosRequestConfig } from "axios";

export const getCertificateWithId = async (idCertificate: String) => {
  if (!idCertificate) return null;

  const qa = `${GET_RUCS_BY_ID}?idCertificate=${idCertificate}`;
  const token = localStorage.getItem("access_token");

  const config: AxiosRequestConfig = {
    url: qa,
    method: "get",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios(config);
  const resolve = response.data;
  return resolve.data;
};

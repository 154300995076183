import React, { Dispatch } from "react";
//@ts-ignore
import styled from "./style/isRucsToShow.module.css";
import { IRuc } from "@Models/interfaces/ruc";
import { IModalProps } from "@Models/interfaces/modals";
import RucRowItem from "@Components/ui/atoms/RucRowItem/RucRowItem";
import useScreenWidth from "@Hook/useScreenWidth/useScreenWidth";
// @ts-ignore
import { TableMobile } from "@viuti/recursos";
import { prepareDataTableForMobile } from "../dataTableForMobile/dataTableForMobile";

interface IIsRucsToShowProps {
  rucsList: IRuc[];
  refreshRucsAction: () => Promise<void>;
  setModalProps: Dispatch<IModalProps>;
  setShowModal: Dispatch<boolean>;
}

const IsRucsToShow: React.SFC<IIsRucsToShowProps> = (props) => {
  const { rucsList } = props;
  const screenWidth = useScreenWidth();

  return (
    <section className={styled.itemsRucs__section}>
      {screenWidth >= 900 ? (
        rucsList.map((ruc, index) => {
          return <RucRowItem index={index} key={ruc.idCertificate} rucData={ruc} {...props} />;
        })
      ) : (
        <TableMobile
          dataTable={rucsList.map((ruc, index) => prepareDataTableForMobile(ruc, index, screenWidth, props))}
          numberOfRows={rucsList.length}
          isLoading={false}
        />
      )}
    </section>
  );
};

export default IsRucsToShow;

import React from "react";
//@ts-ignore
import styled from "./style/rucName.module.css";

interface IRucNameProps {
  businessName: string;
  ruc: string;
  index: number;
}

const NO_BUSINESS_NAME = "Sin razón social";
const NO_RUC = "Sin RUC";

const RucName: React.SFC<IRucNameProps> = (props) => {
  const { businessName, ruc } = props;
  return (
    <p className={styled.itemsRucs__id}>
      <p id={"billing-rucList-item-" + props.index + "-text-rucName"}>
        {businessName !== null ? businessName : NO_BUSINESS_NAME}
      </p>
      <p id={"billing-rucList-item-" + props.index + "-text-ruc"}>({ruc !== null ? ruc : NO_RUC})</p>
    </p>
  );
};

export default RucName;

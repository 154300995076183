import { BILLING_ROUTE } from "@Models/consts/routes";

const TITLE_HEADER = "Autorizar RUC para Facturación electrónica";

export const headerProps = (otherProps, navigate) => ({
  previousAction: () => navigate(BILLING_ROUTE.ListRucs),
  title: TITLE_HEADER,
  buttonProps: {
    ...otherProps,
    isPrimary: true,
    handleClick: otherProps.handleClick,
  },
});

export const DATE_LABEL = "Fecha de vencimiento";
export const CERTIFICATE_PASSWORD_LABEL = "Clave de certificado";

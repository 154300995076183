import React from "react";
//@ts-ignore
import styled from "./style/rucStatus.module.css";
import { IRucState } from "@Models/interfaces/ruc";
import { AUTORIZADO, NO_AUTORIZADO, PENDIENTE } from "@Models/consts/rucStatus.consts";

interface IRucStatusProps {
  nameState: IRucState;
  index: number;
}

const RucStatus: React.SFC<IRucStatusProps> = (props) => {
  const { nameState } = props;

  if (nameState === AUTORIZADO)
    return (
      <p id={"billing-rucList-item-" + props.index + "-text-rucStatus-approve"} className={styled.itemsRucs__stateAutorizado}>
        Autorizado
      </p>
    );

  if (nameState === NO_AUTORIZADO)
    return (
      <p id={"billing-rucList-item-" + props.index + "-text-rucStatus-reject"} className={styled.itemsRucs__stateNoAutorizado}>
        No autorizado
      </p>
    );

  if (nameState === PENDIENTE)
    return (
      <p id={"billing-rucList-item-" + props.index + "-text-rucStatus-pending"} className={styled.itemsRucs__statePendiente}>
        Pendiente
      </p>
    );
};

export default RucStatus;

import { IRuc } from "@Models/interfaces/ruc";
import getRucsServices from "@Services/listRuc/getRucsServices";

export const getRucsAdapter = async (setStateResponse, setHasCriticalError) => {
  const response = await getRucsServices();

  if (response.isSuccess) {
    if (response.data.length === 0) return [];

    return response.data.map(
      (ruc): IRuc => ({
        idCertificate: ruc.idCertificate,
        businessName: ruc.businessName,
        ruc: ruc.ruc,
        estate: ruc.estate,
        expirationDate: ruc.expirationDate,
        nameState: ruc.nameState,
      })
    );
  } else {
    setHasCriticalError(true);
    setStateResponse({
      message: response.error,
      status: 400,
    });
    return response.error;
  }
};

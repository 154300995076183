// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s0O3nNSldyZp5qjinhx6 {
  display: grid;
  grid-template-columns: 400px auto 200px 350px 26px 26px;
  padding: 24px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid #45348e;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: 2px solid #ba1a1a;
  background-color: #ba1a1a1b;
  gap: 16px;
  color: #ba1a1a;
  font-family: "Mulish", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  letter-spacing: 0.2px;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/atoms/RucRowItem/components/DeleteRucRowItem/style/deleteRucRowItem.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uDAAuD;EACvD,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,mBAAmB;EACnB,kBAAkB;EAClB,yBAAyB;EACzB,aAAa;EACb,mBAAmB;EACnB,2BAA2B;EAC3B,yBAAyB;EACzB,2BAA2B;EAC3B,SAAS;EACT,cAAc;EACd,iCAAiC;EACjC,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,qBAAqB;AACvB","sourcesContent":[".itemsRucs__article__deleted {\n  display: grid;\n  grid-template-columns: 400px auto 200px 350px 26px 26px;\n  padding: 24px;\n  justify-content: space-between;\n  align-items: center;\n  align-self: stretch;\n  border-radius: 4px;\n  border: 1px solid #45348e;\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  border: 2px solid #ba1a1a;\n  background-color: #ba1a1a1b;\n  gap: 16px;\n  color: #ba1a1a;\n  font-family: \"Mulish\", sans-serif;\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 900;\n  line-height: normal;\n  letter-spacing: 0.2px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"itemsRucs__article__deleted": `s0O3nNSldyZp5qjinhx6`
};
export default ___CSS_LOADER_EXPORT___;

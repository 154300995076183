import { UPDATATE_CERTIFICATE } from "@Models/fetchUrls/listRucUrl";
import axios, { AxiosRequestConfig } from "axios";

export const updateCertificateServices = async (rucObj: { businessName; expirationDate; claveCertificado; ruc; file; id }) => {
  const token = localStorage.getItem("access_token");

  const formData = new FormData();
  formData.append("BussinesName", rucObj.businessName);
  formData.append("ExpirationDate", rucObj.expirationDate);
  formData.append("CertificatePassword", rucObj.claveCertificado);
  formData.append("Ruc", rucObj.ruc);
  formData.append("AttachedFiles", rucObj.file);
  formData.append("IdCertificate", rucObj.id);
  formData.append("State", "2");

  const config: AxiosRequestConfig = {
    url: UPDATATE_CERTIFICATE,
    method: "put",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: formData,
  };

  try {
    const response = await axios(config);
    const resolve = response.data;
    return resolve.data;
  } catch (error) {
    return {
      isError: true,
      errorMsg: error.response.data.message,
    };
  }
};

export default updateCertificateServices;

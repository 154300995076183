// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.yvv08jw4FhqVQuOgVBkD,
.w_FtCam53kiRgj3_jWP4,
.pqPUOU3Ws12K0ppVtnff {
  display: flex;
  height: 30px;
  padding: 5px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 38px;
  color: var(--black-500, #424242);
  text-align: center;
  font-family: "Mulish", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 93.5%; /* 11.22px */
}

.yvv08jw4FhqVQuOgVBkD {
  border-radius: 38px;
  background: #d7fed0;
}
.w_FtCam53kiRgj3_jWP4 {
  border-radius: 38px;
  background: #fbb;
}
.pqPUOU3Ws12K0ppVtnff {
  border-radius: 38px;
  background: #ffe8bb;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/atoms/RucRowItem/components/RucItem/components/RucStatus/style/rucStatus.module.css"],"names":[],"mappings":"AAAA;;;EAGE,aAAa;EACb,YAAY;EACZ,iBAAiB;EACjB,uBAAuB;EACvB,mBAAmB;EACnB,mBAAmB;EACnB,gCAAgC;EAChC,kBAAkB;EAClB,iCAAiC;EACjC,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,kBAAkB,EAAE,YAAY;AAClC;;AAEA;EACE,mBAAmB;EACnB,mBAAmB;AACrB;AACA;EACE,mBAAmB;EACnB,gBAAgB;AAClB;AACA;EACE,mBAAmB;EACnB,mBAAmB;AACrB","sourcesContent":[".itemsRucs__stateAutorizado,\n.itemsRucs__stateNoAutorizado,\n.itemsRucs__statePendiente {\n  display: flex;\n  height: 30px;\n  padding: 5px 16px;\n  justify-content: center;\n  align-items: center;\n  border-radius: 38px;\n  color: var(--black-500, #424242);\n  text-align: center;\n  font-family: \"Mulish\", sans-serif;\n  font-size: 12px;\n  font-style: normal;\n  font-weight: 700;\n  line-height: 93.5%; /* 11.22px */\n}\n\n.itemsRucs__stateAutorizado {\n  border-radius: 38px;\n  background: #d7fed0;\n}\n.itemsRucs__stateNoAutorizado {\n  border-radius: 38px;\n  background: #fbb;\n}\n.itemsRucs__statePendiente {\n  border-radius: 38px;\n  background: #ffe8bb;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"itemsRucs__stateAutorizado": `yvv08jw4FhqVQuOgVBkD`,
	"itemsRucs__stateNoAutorizado": `w_FtCam53kiRgj3_jWP4`,
	"itemsRucs__statePendiente": `pqPUOU3Ws12K0ppVtnff`
};
export default ___CSS_LOADER_EXPORT___;

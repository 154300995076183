import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useAdapter from "../functions/useAdapter";
import validTheCertificate from "../functions/validTheCertificate";

const useDisabledToContinue = (props) => {
  const navigate = useNavigate();
  const { rucData, setRucData, file, setShowModal, setModalProps, logo } = props;
  const [isDisabled, setIsDisabled] = useState(true);
  const [inProgress, setInProgress] = useState(false);
  const [inValidation, setInValidation] = useState(false);
  const [isAdding, setIsAdding] = useState(false);

  useEffect(() => {
    const isRucEmpty = rucData.ruc === "";
    if (isRucEmpty) setInValidation(false);

    setIsDisabled(true);

    //2.1 Check if the passaword is valid
    const isPasswordValid = typeof rucData.claveCertificado === "string" && rucData.claveCertificado !== "";
    if (isPasswordValid) {
      //3. Check if the file is valid
      const isFileValid = file !== null && file !== undefined;
      if (isFileValid) setIsDisabled(false);
      else {
        setInProgress(false);
        setInValidation(false);
        setIsDisabled(true);
      }
    }

    //2.2 If I remove the file when the ruc was validated before, I need to reset the data
    const isFileRemovedBeforeValidation = file === null && rucData.ruc !== null && inValidation;
    if (isFileRemovedBeforeValidation) {
      // 3 Reset the data
      setRucData({
        ruc: "",
        businessName: "",
        expirationDate: "",
      });
    }

    //2.3 If I remove the file when the password was validated before, I need to reset the data
    const isFileRemovedAfterValidation = file !== null && rucData.claveCertificado === null;
    if (isFileRemovedAfterValidation) {
      setRucData({
        ...rucData,
        claveCertificado: "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rucData, file]);

  // Set the text of the button
  const textBttn =
    isDisabled || !inValidation ? "Autorizar" : inProgress ? "Autorizando..." : !isAdding ? "Guardar" : "Guardando...";

  // Return the props
  return {
    isDisabled: isDisabled || inProgress || isAdding,
    textBttn,
    handleClick: inValidation
      ? async () => useAdapter({ rucData, file, setShowModal, setModalProps, navigate, setIsAdding, logo })
      : async () => validTheCertificate(setShowModal, setModalProps, rucData, setRucData, file, setInValidation, setInProgress),
  };
};

export default useDisabledToContinue;

import { HeaderModalBig } from "@Components/ui";

const useShowBigPopUp = (setShowModal, setModalProps, setToast, setRuc, id) => {
  setShowModal(true);

  setModalProps({
    showButtons: {
      showButtonClose: false,
    },
    children: <HeaderModalBig setToast={setToast} setRuc={setRuc} id={id} setShowModal={setShowModal} />,
    actionButtonClose: () => setShowModal(false),
    isSmallSize: false,
  });
};

export default useShowBigPopUp;

//@ts-ignore
import style from "./headerModal.module.css";
//@ts-ignore
import { ModalAcceptCancel } from "@viuti/recursos";
import TextInputBuilder from "@Components/builder/TextInput";
import { useEffect, useState } from "react";
import useValidation from "@Pages/newRuc/components/FieldsWrapper/hooks/useValidation";
import addNewRucAdapter from "@Adapter/addNewRucAdapter/addNewRucAdapter";
import updateCertificateServices from "@Services/editRuc/updateCertificate";
import FileUploader from "@Components/ui/molecules/FileUploader/FileUploader";

const HeaderModalBig = ({ setToast, setRuc, id, setShowModal }) => {
  const [file, setFile] = useState(null);
  const [certificate, setCertificate] = useState(null);
  const certificateValidate = useValidation(certificate, true);
  const [allDataComplete, setAllDataComplete] = useState(false);
  const [inValidation, setInValidation] = useState(false);

  const updateCertificate = async () => {
    if (allDataComplete) {
      setInValidation(true);
      const result = await addNewRucAdapter({ password: certificateValidate.valueInput, file });
      if (result?.error === true) {
        setInValidation(false);
        setToast({ message: "Contraseña incorrecta", color: 400 });
        setTimeout(() => {
          setToast(null);
        }, 3000);
      } else {
        const response = await updateCertificateServices({
          businessName: result.businessName,
          expirationDate: result.expirationDate,
          claveCertificado: certificateValidate.valueInput,
          ruc: result.ruc,
          file,
          id: id,
        });

        if (!response?.isError) {
          setRuc({ ...result, passwordCertificate: certificate, file });
          setShowModal(false);
          setToast({ message: "El ruc se actualizó con exito", color: 200 });
          setInValidation(false);
          setTimeout(() => {
            setToast(null);
          }, 3000);
        } else {
          setToast({ message: response.errorMsg || "Ups! ocurrió un problema, vuelva a intentarlo mas tarde", color: 400 });
          setInValidation(false);
          setTimeout(() => {
            setToast(null);
          }, 3000);
        }
      }
    } else {
      setToast({ message: "Todos los campos son obligatorios", color: 400 });
    }
  };

  useEffect(() => {
    setAllDataComplete(false);
    if (certificateValidate.valueInput !== "" && certificateValidate.valueInput !== null && file) setAllDataComplete(true);
  }, [certificateValidate.valueInput, file]);

  const headerProps = {
    showButtons: {
      showButtonClose: true,
      showButtonTwo: true,
    },
    title: "Actualizar certificado",
    children: (
      <main className={style.modal}>
        <p className={style.text_p}>
          Aprende a conseguir tu certificado de facturación electrónica{" "}
          <a className={style.text_a} href="https://cpe.sunat.gob.pe/certificado-digital" target="_blank">
            aquí
          </a>
        </p>
        <FileUploader file={file} setFile={setFile} />
        <section className={style.modal__clave}>
          <TextInputBuilder required={true} props={certificateValidate} label="Clave del certificado" keyboardType="password" />
        </section>
      </main>
    ),
    buttonTwo: {
      textButtonTwo: "Actualizar",
      actionButtonTwo: () => updateCertificate(),
      isDisabled: inValidation,
      isLoading: inValidation,
    },
    actionButtonClose: () => setShowModal(false),
  };

  return <ModalAcceptCancel {...headerProps} />;
};

export default HeaderModalBig;

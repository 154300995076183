import React from "react";
//@ts-ignore
import styled from "./style/fieldsWrapper.module.css";
import TextInputBuilder from "@Components/builder/TextInput";
import useValidation from "./hooks/useValidation";
import useCompleteData from "./hooks/useCompleteData";
import { IRuc } from "@Models/interfaces/ruc";
import { CERTIFICATE_PASSWORD_LABEL, DATE_LABEL } from "@Models/consts/newRucs.const";

interface IFieldsWrapperProps {
  rucData: IRuc;
  setRucData: any;
}

const FieldsWrapper: React.SFC<IFieldsWrapperProps> = (props) => {
  const { setRucData, rucData } = props;
  const rucValue = useValidation(rucData.ruc);
  const businessName = useValidation(rucData.businessName);
  const certificateDate = useValidation(rucData.expirationDate);
  const certificatePassword = useValidation(rucData.claveCertificado, true);
  useCompleteData(
    {
      rucValue: rucValue.valueInput,
      businessName: businessName.valueInput,
      certificateDate: certificateDate.valueInput,
      certificatePassword: certificatePassword.valueInput,
    },
    setRucData
  );

  return (
    <section className={styled.newRucsPage__Section}>
      <TextInputBuilder props={rucValue} required={true} label={"Ruc"} disabled={true} />
      <TextInputBuilder props={businessName} label={"Razón social"} disabled={true} />
      <TextInputBuilder props={certificateDate} label={DATE_LABEL} disabled={true} />
      <TextInputBuilder
        props={certificatePassword}
        required={true}
        label={CERTIFICATE_PASSWORD_LABEL}
        keyboardType="password"
        disabled={false}
      />
    </section>
  );
};

export default FieldsWrapper;

import { HeaderModal } from "@Components/ui";
import { Dispatch } from "react";

const showPopUp = (
  setShowModal: Dispatch<boolean>,
  setModalProps: (value: any) => void,
  errorTitle: string,
  errorMsg: string
) => {
  //1. Show the modal
  setShowModal(true);

  // 2. Set the props into the modal
  setModalProps({
    showButtons: {
      showButtonClose: true,
    },
    children: <HeaderModal title={errorTitle} content={errorMsg} />,
    actionButtonClose: () => setShowModal(false),
  });
};

export default showPopUp;

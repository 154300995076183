import { ADD_NEW_RUC_URL } from "@Models/fetchUrls/newRucUrl";
import { IRuc } from "@Models/interfaces/ruc";
import axios from "axios";
// @ts-ignore
import { trackUserAction } from "@viuti/recursos";

const addNewRucServices: any = async (rucObj: IRuc, logo) => {
  const agrega_nuevo_ruc = 16;
  trackUserAction(agrega_nuevo_ruc);
  const formData = new FormData();
  formData.append("BusinessName", rucObj.businessName);
  formData.append("ExpirationDate", rucObj.expirationDate);
  formData.append("CertificatePassword", rucObj.claveCertificado);
  formData.append("Ruc", rucObj.ruc);
  formData.append("AttachedFileCertificate", rucObj.file);
  formData.append("AttachedFileLogo", logo);

  const bearerToken = localStorage.getItem("access_token");

  const headers = {
    Authorization: `Bearer ${bearerToken}`,
  };

  const response = await axios.post(ADD_NEW_RUC_URL, formData, {
    headers: headers,
  });

  return response;
};

export default addNewRucServices;

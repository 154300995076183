// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wt6TBy4By7CNVxhNs81m {
  color: var(--carpenter-core-color-light-bad, #ba1a1a);
  font-family: "Mulish", sans-serif;
  font-size: var(--font-size-reading);
  font-style: normal;
  font-weight: 900;
  text-align: start;
}

.Hc3EY4WFNwLEkVdCFHqB {
  color: #937cf4;
  font-family: "Mulish", sans-serif;
  font-size: var(--font-size-reading);
  font-style: normal;
  font-weight: 900;
  text-align: start;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/atoms/RucRowItem/components/RucItem/components/RucStatusMsg/style/rucStatusMsg.module.css"],"names":[],"mappings":"AAAA;EACE,qDAAqD;EACrD,iCAAiC;EACjC,mCAAmC;EACnC,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,cAAc;EACd,iCAAiC;EACjC,mCAAmC;EACnC,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;AACnB","sourcesContent":[".itemsRucs__expired {\n  color: var(--carpenter-core-color-light-bad, #ba1a1a);\n  font-family: \"Mulish\", sans-serif;\n  font-size: var(--font-size-reading);\n  font-style: normal;\n  font-weight: 900;\n  text-align: start;\n}\n\n.itemsRucs__noExpired {\n  color: #937cf4;\n  font-family: \"Mulish\", sans-serif;\n  font-size: var(--font-size-reading);\n  font-style: normal;\n  font-weight: 900;\n  text-align: start;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"itemsRucs__expired": `wt6TBy4By7CNVxhNs81m`,
	"itemsRucs__noExpired": `Hc3EY4WFNwLEkVdCFHqB`
};
export default ___CSS_LOADER_EXPORT___;

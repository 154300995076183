// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FYG8SuM1rA8Ubnb6XV9W {
  width: 100%;
  display: flex;
  border-top: 1px solid #dad1f4;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
  padding: 16px 20px;
  cursor: default;
  min-height: 86px;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/atoms/RucRowItem/components/RucItem/style/rucItem.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,6BAA6B;EAC7B,SAAS;EACT,mBAAmB;EACnB,8BAA8B;EAC9B,kBAAkB;EAClB,eAAe;EACf,gBAAgB;AAClB","sourcesContent":[".itemsRucs__article {\n  width: 100%;\n  display: flex;\n  border-top: 1px solid #dad1f4;\n  gap: 1rem;\n  align-items: center;\n  justify-content: space-between;\n  padding: 16px 20px;\n  cursor: default;\n  min-height: 86px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"itemsRucs__article": `FYG8SuM1rA8Ubnb6XV9W`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.joIbKOITESCojVdRtCXL {
  display: grid;
  grid-template-columns: auto auto;
  gap: 24px;
  margin-top: 0px;
  margin-bottom: 56px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/editRuc/components/fieldWrapper/fieldWrapper.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,gCAAgC;EAChC,SAAS;EACT,eAAe;EACf,mBAAmB;AACrB","sourcesContent":[".editRucsPage__Section {\n  display: grid;\n  grid-template-columns: auto auto;\n  gap: 24px;\n  margin-top: 0px;\n  margin-bottom: 56px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"editRucsPage__Section": `joIbKOITESCojVdRtCXL`
};
export default ___CSS_LOADER_EXPORT___;

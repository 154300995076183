import { TextInputBuilderInterface } from "@Models/interfaces/builder";
//@ts-ignore
import { TextInput } from "@viuti/recursos";

const TextInputBuilder = ({ props, label, required, keyboardType, disabled = false }: TextInputBuilderInterface) => {
  const { valueInput, changeValueInput, validateValueInput, error } = props;
  return (
    <TextInput
      label={label}
      value={valueInput}
      handleChange={changeValueInput}
      handleBlur={validateValueInput}
      error={error}
      touched={true}
      required={required}
      keyboardType={keyboardType}
      disabled={disabled}
    />
  );
};

export default TextInputBuilder;

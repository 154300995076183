import HeaderModal from "@Components/ui/atoms/headerModal/HeaderModal";
import { useEffect, useState } from "react";

const useUserConfirmation = (setShowModal, setModalProps) => {
  const [userConfirmation, setUserConfirmation] = useState(null);

  const DELETE_TITLE = "Eliminar RUC";
  const DELETE_CONTENT =
    "¿Estás seguro de que deseas proceder con la eliminación de este RUC? Esta acción es irreversible y no se puede deshacer.";

  const showButtonProps = {
    showButtonOne: false,
    showButtonTwo: true,
    showButtonClose: true,
  };

  const userNeedToConfirmDelete = () => {
    setShowModal(true);
    setModalProps({
      showButtons: showButtonProps,
      children: <HeaderModal title={DELETE_TITLE} content={DELETE_CONTENT} />,
      buttonTwo: {
        textButtonTwo: "Confirmar",
        actionButtonTwo: () => {
          setShowModal(false);
          setUserConfirmation(true);
        },
      },
      actionButtonClose: () => {
        setShowModal(false);
        setUserConfirmation(false);
      },
    });
  };

  return [userConfirmation, userNeedToConfirmDelete];
};

export default useUserConfirmation;

import { BILLING_ROUTE } from "@Models/consts/routes";

const TITLE_HEADER = "Actualizar RUC para Facturación electrónica";
const TITLE_HEADER_CERTIFICATE = "Certificado";
const TITLE_HEADER_CERTIFICATE_POPUP = "Actualizar certificado";

export const headerProps = (navigate) => ({
  previousAction: () => navigate(BILLING_ROUTE.ListRucs),
  title: TITLE_HEADER,
  buttonProps: {
    isHidden: true,
  },
});

export const headerPropsCertificate = (showBigModal) => ({
  title: TITLE_HEADER_CERTIFICATE,
  buttonProps: {
    isPrimary: true,
    handleClick: () => showBigModal(),
    textBttn: "Actualizar",
  },
});

export const headerPropsCertificatePopUp = (showBigModal, navigate) => ({
  title: TITLE_HEADER_CERTIFICATE_POPUP,
  buttonProps: {
    isHidden: true,
  },
  isSmallSize: false,
});

import addNewRucListAdapter from "@Adapter/addNewRucAdapter/addNewRucListAdapter";
import showPopUp from "../hook/useShowPopUp";
import { HeaderModal } from "@Components/ui";
import { BILLING_ROUTE } from "@Models/consts/routes";
import axios from "axios";

const useAdapter = async ({ rucData, file, setShowModal, setModalProps, navigate, setIsAdding, logo }) => {
  setIsAdding(true);
  const response = await addNewRucListAdapter({ ...rucData, file }, logo);
  if (axios.isAxiosError(response)) {
    showPopUp(setShowModal, setModalProps, "¡Ups! ocurrió un problema", response?.response?.data?.message);
    setIsAdding(false);
  } else {
    setShowModal(true);

    setModalProps({
      showButtons: {
        showButtonClose: false,
        showButtonTwo: true,
      },
      children: (
        <HeaderModal title={"Operación realizada con éxito"} content={"El RUC se ha agregado con éxito."} icon="success" />
      ),
      buttonTwo: {
        textButtonTwo: "Cerrar",
        actionButtonTwo: () => navigate(BILLING_ROUTE.ListRucs),
      },
      actionButtonClose: () => {},
    });
    setIsAdding(false);
  }
};

export default useAdapter;

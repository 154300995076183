import React from "react";
//@ts-ignore
import styled from "./style/deleteRucRowItem.module.css";
import { DeleteIcon } from "@Models/consts/icons";

const DELETING_MSG = "Eliminando esta autorizacion...";

const DeleteRucRowItem: React.SFC = () => {
  return (
    <article className={styled.itemsRucs__article__deleted}>
      <img width={24} src={DeleteIcon} alt="trash can icon" />
      {DELETING_MSG}
    </article>
  );
};

export default DeleteRucRowItem;

import { useEffect, useState } from "react";
import { IRuc } from "@Models/interfaces/ruc";
import { getRucsAdapter } from "@Adapter/listRucAdapter/getRucAdapter.adapter";

const useInitState = (setStateResponse) => {
  const [rucsList, setRucsList] = useState<IRuc[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasCriticalError, setHasCriticalError] = useState(false);

  const asyncGetRucs = async () => {
    const initRucsList = await getRucsAdapter(setStateResponse, setHasCriticalError);
    if (Array.isArray(initRucsList)) setRucsList(initRucsList);
    setIsLoading(false);
  };

  useEffect(() => {
    asyncGetRucs();
  }, []);

  return { rucsList, refreshRucs: asyncGetRucs, isLoading, hasCriticalError };
};

export default useInitState;

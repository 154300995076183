import React from "react";
//@ts-ignore
import styled from "./fieldWrapper.module.css";
import TextInputBuilder from "@Components/builder/TextInput";
import { IRuc } from "@Models/interfaces/ruc";

interface IFieldsWrapperProps {
  rucData: IRuc;
}

const FieldsWrapper: React.SFC<IFieldsWrapperProps> = (props) => {
  const ruc = props.rucData.ruc;
  const razonSocial = props.rucData.businessName;
  const certificateDate = props.rucData.expirationDate.split(" ");
  //@ts-ignore
  const certificatePassword = props.rucData.passwordCertificate;
  return (
    <section className={styled.editRucsPage__Section}>
      <TextInputBuilder props={{ valueInput: ruc }} label={"Ruc"} disabled={true} />
      <TextInputBuilder props={{ valueInput: razonSocial }} label={"Razón social"} disabled={true} />
      <TextInputBuilder props={{ valueInput: certificateDate[0] }} label={"Fecha de vencimiento"} disabled={true} />
      <TextInputBuilder
        props={{ valueInput: certificatePassword }}
        label={"Clave del certificado"}
        disabled={true}
        keyboardType={"password"}
      />
    </section>
  );
};

export default FieldsWrapper;

interface DateObject {
  day: number;
  month: number;
  year: number;
}

const MILLISECONDS_PER_SECOND = 1000;
const SECONDS_PER_MINUTE = 60;
const MINUTES_PER_HOUR = 60;
const HOURS_PER_DAY = 24;

const MILLISECONDS_PER_DAY = MILLISECONDS_PER_SECOND * SECONDS_PER_MINUTE * MINUTES_PER_HOUR * HOURS_PER_DAY;

const getDifferenceInDays = (date: DateObject): number => {
  const today = new Date();
  const targetDate = new Date(date.year, date.month - 1, date.day);

  today.setHours(0, 0, 0, 0);
  targetDate.setHours(0, 0, 0, 0);

  const differenceInMilliseconds = targetDate.getTime() - today.getTime();
  const differenceInDays = Math.floor(differenceInMilliseconds / MILLISECONDS_PER_DAY);

  return differenceInDays;
};

export default getDifferenceInDays;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QeYjaJkpZzIvQs2sqDP8 {
  display: grid;
  width: 1000px;
}

.MkjbrIC5vu949mWO9BEg {
  margin-top: 16px;
  width: 400px;
}

.achAXll8tpHHPOWbXoro {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid #cfcfcf;
  padding-top: 12px;
  margin-top: 24px;
}

.JLyT1d4godGt8xsjBx8c {
  margin-top: 0px;
  color: #45348e;
  font-family: "Mulish", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.2px;
  margin-bottom: 1rem;
}

.wRpPhjnkLQftD8KDTnNa {
  color: #45348e;
  font-family: "Mulish", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.2px;
  text-decoration: underline;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/atoms/headerModalBig/headerModal.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,aAAa;AACf;;AAEA;EACE,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,yBAAyB;EACzB,6BAA6B;EAC7B,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,cAAc;EACd,iCAAiC;EACjC,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,qBAAqB;EACrB,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,iCAAiC;EACjC,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,qBAAqB;EACrB,0BAA0B;AAC5B","sourcesContent":[".modal {\n  display: grid;\n  width: 1000px;\n}\n\n.modal__clave {\n  margin-top: 16px;\n  width: 400px;\n}\n\n.modal__button {\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  border-top: 1px solid #cfcfcf;\n  padding-top: 12px;\n  margin-top: 24px;\n}\n\n.text_p {\n  margin-top: 0px;\n  color: #45348e;\n  font-family: \"Mulish\", sans-serif;\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: normal;\n  letter-spacing: 0.2px;\n  margin-bottom: 1rem;\n}\n\n.text_a {\n  color: #45348e;\n  font-family: \"Mulish\", sans-serif;\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 700;\n  line-height: normal;\n  letter-spacing: 0.2px;\n  text-decoration: underline;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `QeYjaJkpZzIvQs2sqDP8`,
	"modal__clave": `MkjbrIC5vu949mWO9BEg`,
	"modal__button": `achAXll8tpHHPOWbXoro`,
	"text_p": `JLyT1d4godGt8xsjBx8c`,
	"text_a": `wRpPhjnkLQftD8KDTnNa`
};
export default ___CSS_LOADER_EXPORT___;

import forge from "node-forge";

const addNewRucAdapter = async ({ password, file }: { password: string | null; file: File | null }) => {
  if (!password || !file) return;
  try {
    const pfxData = await readFileAsync(file);

    // Convierte el ArrayBuffer a ByteStringBuffer
    const pfxBuffer = forge.util.createBuffer(pfxData);
    const p12Asn1 = forge.asn1.fromDer(pfxBuffer);
    const p12 = forge.pkcs12.pkcs12FromAsn1(p12Asn1, password);

    const bags = p12.getBags({ bagType: forge.pki.oids.certBag });
    const cert = bags[forge.pki.oids.certBag][0].cert;

    const attrs = cert.subject.attributes;
    let ruc: string | any[] | null = null;
    let businessName: string | any[] | null = null;
    let fechaVencimiento: string | null = null;

    for (const attr of attrs) {
      const extension = file?.name.split(".").pop();
      if (attr.name === "organizationName") businessName = attr.value;
      if (attr.name === "organizationalUnitName" && extension === "pfx") ruc = attr.value;
      if (extension === "p12") {
        const attrNumber = Number(attr.value);
        if (!isNaN(attrNumber)) ruc = attr.value;
        //@ts-ignore
        const splitAttr = attr.value.split(" ");
        for (const item of splitAttr) {
          const itemNumber = Number(item);
          if (!isNaN(itemNumber)) ruc = item;
        }
      }
    }
    const notAfter = cert.validity.notAfter;
    fechaVencimiento = notAfter.toISOString().split("T")[0];

    return {
      expirationDate: fechaVencimiento,
      businessName,
      ruc,
    };
  } catch (error) {
    return {
      error: true,
      message: "La lectura del certificado no se pudo realizar. Es posible que la contraseña del certificado no sea correcta.",
    };
  }
};

export default addNewRucAdapter;

function readFileAsync(file: File): Promise<ArrayBuffer> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      if (event.target?.result instanceof ArrayBuffer) {
        resolve(event.target.result);
      } else {
        reject(new Error("Invalid result type from FileReader"));
      }
    };
    reader.onerror = (event) => reject(event.target?.error);
    reader.readAsArrayBuffer(file);
  });
}

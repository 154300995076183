import onDeleteRucOfList from "@Services/listRuc/deleteRucOfList";
import { AxiosResponse } from "axios";

interface IRucActionsProps {
  idCertificate: number;
  refreshRucsAction: any;
  setModalProps: any;
  setShowModal: any;
  setIsDeleting: any;
}

const useDeleteRucAdapter = async (props: IRucActionsProps): Promise<AxiosResponse<any, any>> => {
  const { idCertificate, setIsDeleting, setShowModal, setModalProps } = props;

  // Is a valid certificate type?
  if (typeof idCertificate !== "number") return;

  //deleting process
  setIsDeleting(true);

  const response = await onDeleteRucOfList(idCertificate);

  return response;
};

export default useDeleteRucAdapter;

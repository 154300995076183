import { LoadingPulseIcon } from "@Models/consts/icons";

const Loading = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img src={LoadingPulseIcon} alt="loading" width={200} height={200} />
    </div>
  );
};

export default Loading;

import { DELETE_URL } from "@Models/fetchUrls/deleteUrl";
import getBarerToken from "@Services/getBarerToken";
import axios from "axios";
//@ts-ignore
import { trackUserAction } from "@viuti/recursos";

const onDeleteRucOfList = async (idCertificate) => {
  const barerToken = getBarerToken();

  const headers = {
    Authorization: `Bearer ${barerToken}`,
  };

  const source = axios.CancelToken.source();
  const elimina_ruc = 17;
  trackUserAction(elimina_ruc);
  const response = await axios.delete(DELETE_URL(idCertificate), {
    headers: headers,
    cancelToken: source.token,
    timeout: 5000,
  });
  return response;
};

export default onDeleteRucOfList;

import { useLocation } from "react-router-dom";

const getIdUrlPath = () => {
  const location = useLocation();
  const pathSegments = location.pathname.split("/");
  const lastSegments = pathSegments[pathSegments.length - 1];
  return lastSegments;
};

export default getIdUrlPath;

import { GET_RUCS } from "@Models/fetchUrls/listRucUrl";
import getBarerToken from "../getBarerToken";
import axios from "axios";
// @ts-ignore
import { trackUserAction } from "@viuti/recursos";

const getRucsServices = async () => {
  const barerToken = getBarerToken();
  const headers = {
    Authorization: `Bearer ${barerToken}`,
  };

  const ingresa_vista_facturacion = 14;
  trackUserAction(ingresa_vista_facturacion);
  try {
    const response = await axios.get(GET_RUCS, { headers });

    return {
      isSuccess: true,
      status: response.status,
      data: response.data.data,
    };
  } catch (error) {
    return {
      isSuccess: false,
      error: error?.response?.data?.message || "Error al obtener los rucs",
      data: [],
    };
  }
};

export default getRucsServices;

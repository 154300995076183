import { monthInSpanish } from "@Models/consts/monthsStr.consts";
import type { ExpirationDate } from "@Models/types/dates";

export const NO_DATE = "Sin fecha asignada";

const convertExpirationDate = (expirationDate: string | null) => {
  if (expirationDate === null) return NO_DATE;

  const date = new Date(expirationDate);

  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return {
    dayString: addLeadingZero(day),
    day: day,
    monthString: monthInSpanish[month - 1].toUpperCase(),
    month: month,
    yearString: year.toString(),
    year: year,
  };
};

export default convertExpirationDate;

function addLeadingZero(number: number): string {
  if (number < 10) {
    return `0${number}`;
  } else {
    return `${number}`;
  }
}

import { getCertificateWithId } from "@Services/editRuc/getCertificateWithId";

export const getCertificateByIdAdapter = async (id: string, setStateResponse) => {
  try {
    const response = await getCertificateWithId(id);

    const frontObject = {
      idCertificate: response.idCertificate,
      businessName: response.businessName,
      ruc: response.ruc,
      estate: response.estate,
      expirationDate: response.expirationDate,
      nameState: response.nameState,
      passwordCertificate: response.passwordCertificate,
    };

    return frontObject;
  } catch (error) {
    setStateResponse({
      message: error?.response?.data?.message || "Error al obtener el certificado",
      status: 400,
    });
    return {
      idCertificate: "",
      businessName: "",
      ruc: "",
      estate: "",
      expirationDate: "",
      nameState: "",
      passwordCertificate: "",
    };
  }
};

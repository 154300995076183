// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Y_MRa8mugNXuUlo0KtYO {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  margin-top: 16px;
}

.rj9kbDXt0mDPjWcS2Hr3 {
  color: #45348e;
  font-family: "Mulish", sans-serif;
  font-size: var(--font-size-reading);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.2px;
}

.O2MAc1aQqCcU5BK9q7Qh {
  color: #45348e;
  font-family: "Mulish", sans-serif;
  font-size: var(--font-size-reading);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.2px;
  text-decoration: underline;
}

.sPbsp5B_H3UoPq2iwkhK {
  margin-top: 16px;
  padding: 12px 24px;
  display: flex;
  gap: 24px;
  border-radius: 8px;
  border: 1.5px solid #45348e;
  background-color: #947cf434;
}
`, "",{"version":3,"sources":["webpack://./src/pages/newRuc/style/newRucsPage.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,iCAAiC;EACjC,mCAAmC;EACnC,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,qBAAqB;AACvB;;AAEA;EACE,cAAc;EACd,iCAAiC;EACjC,mCAAmC;EACnC,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,qBAAqB;EACrB,0BAA0B;AAC5B;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,aAAa;EACb,SAAS;EACT,kBAAkB;EAClB,2BAA2B;EAC3B,2BAA2B;AAC7B","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  overflow-y: auto;\n  margin-top: 16px;\n}\n\n.newRucsPage__p {\n  color: #45348e;\n  font-family: \"Mulish\", sans-serif;\n  font-size: var(--font-size-reading);\n  font-style: normal;\n  font-weight: 500;\n  line-height: normal;\n  letter-spacing: 0.2px;\n}\n\n.newRucsPage__a {\n  color: #45348e;\n  font-family: \"Mulish\", sans-serif;\n  font-size: var(--font-size-reading);\n  font-style: normal;\n  font-weight: 700;\n  line-height: normal;\n  letter-spacing: 0.2px;\n  text-decoration: underline;\n}\n\n.infoBox {\n  margin-top: 16px;\n  padding: 12px 24px;\n  display: flex;\n  gap: 24px;\n  border-radius: 8px;\n  border: 1.5px solid #45348e;\n  background-color: #947cf434;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Y_MRa8mugNXuUlo0KtYO`,
	"newRucsPage__p": `rj9kbDXt0mDPjWcS2Hr3`,
	"newRucsPage__a": `O2MAc1aQqCcU5BK9q7Qh`,
	"infoBox": `sPbsp5B_H3UoPq2iwkhK`
};
export default ___CSS_LOADER_EXPORT___;

import convertExpirationDate, { NO_DATE } from "@Adapter/dateAdapter/convertExpirationDateAdapter";
import getDifferenceInDays from "@Adapter/dateAdapter/getDifferentInDays";
import React from "react";
//@ts-ignore
import styled from "./style/rucStatusMsg.module.css";

interface IRucStatusMsgProps {
  expirationDate: string;
}

const RED_REFERENCE_DAY = 5;

const RucStatusMsg: React.SFC<IRucStatusMsgProps> = (props) => {
  const { expirationDate } = props;
  const date = convertExpirationDate(expirationDate);

  if (date === NO_DATE) return <p>-</p>;

  const differentBetweenToday = getDifferenceInDays(date);

  if (differentBetweenToday <= 0) return <p className={styled.itemsRucs__expired}>El certificado está vencido.</p>;

  if (differentBetweenToday <= RED_REFERENCE_DAY)
    return <p className={styled.itemsRucs__expired}>El certificado vence en {differentBetweenToday} días.</p>;

  return <p className={styled.itemsRucs__noExpired}>Al certificado le QUEDAN {differentBetweenToday} días.</p>;
};

export default RucStatusMsg;

import { useState } from "react";
// @ts-ignore
import style from "./ListRucsPage.module.css";
import { HeaderNavegationBuilder, Loading } from "@Components/index";
import useInitState from "@Hook/useInitState";
import { headerProps } from "@Models/consts/listRucs.consts";
import {
  CriticalErrorPage,
  ModalAcceptCancel,
  SlideNotification,
  validateModuleAccess,
  UnauthorizedMessage,
  //@ts-ignore
} from "@viuti/recursos";
import { IsNotRucsToShow, IsRucsToShow } from "./views";
import { SHOW_CERTIFICATES_UUID } from "@Models/consts/securityAccess";

const ListRucsPage = () => {
  const [stateResponse, setStateResponse] = useState({
    message: "",
    status: 0,
  });
  const { rucsList, refreshRucs, isLoading, hasCriticalError } = useInitState(setStateResponse);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalProps, setModalProps] = useState(null);

  if (isLoading) return <Loading />;

  if (hasCriticalError)
    return (
      <div id="viuti-front-mainContent">
        <CriticalErrorPage />
      </div>
    );

  return (
    <div id="viuti-front-mainContent">
      {showModal && <ModalAcceptCancel {...modalProps} />}
      <HeaderNavegationBuilder headerProps={headerProps} />
      {!validateModuleAccess(SHOW_CERTIFICATES_UUID, false) ? (
        <div className={style.unauthorized}>
          <UnauthorizedMessage
            restrictedFeatureName={"Listado de certificados de facturación"}
            restrictedUUID={SHOW_CERTIFICATES_UUID}
          />
        </div>
      ) : rucsList.length === 0 ? (
        <IsNotRucsToShow />
      ) : (
        <IsRucsToShow
          rucsList={rucsList}
          refreshRucsAction={refreshRucs}
          setModalProps={setModalProps}
          setShowModal={setShowModal}
        />
      )}
      <SlideNotification state={stateResponse} clearStatus={() => setStateResponse({ message: "", status: 0 })} />
    </div>
  );
};

export default ListRucsPage;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IHEPNOgU3pHRKTzyx23w {
  color: #45348e;
  font-family: "Mulish", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.2px;
  text-align: center;
}

.KGkFHMRfB2oJcbdeUu6A {
  color: #45348e;
  font-family: "Mulish", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  text-align: center;
}

.g32JDkwBCKhjg1cnPJjP {
  width: 79px;
  height: 79px;
  margin: auto;
}

.N6vdUZCXzNFVvBhD_urQ {
  display: grid;
  gap: 32px;
  justify-content: center;
  max-width: 400px;
  padding-bottom: 50px;
}

.HhdvlRP5ir8m3t10_w8H {
  margin: auto;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/atoms/headerModal/headerModal.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,iCAAiC;EACjC,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,qBAAqB;EACrB,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,iCAAiC;EACjC,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,aAAa;EACb,SAAS;EACT,uBAAuB;EACvB,gBAAgB;EAChB,oBAAoB;AACtB;;AAEA;EACE,YAAY;AACd","sourcesContent":[".titleModal {\n  color: #45348e;\n  font-family: \"Mulish\", sans-serif;\n  font-size: 24px;\n  font-style: normal;\n  font-weight: 700;\n  line-height: normal;\n  letter-spacing: 0.2px;\n  text-align: center;\n}\n\n.contentModal {\n  color: #45348e;\n  font-family: \"Mulish\", sans-serif;\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 600;\n  text-align: center;\n}\n\n.iconModal {\n  width: 79px;\n  height: 79px;\n  margin: auto;\n}\n\n.modal {\n  display: grid;\n  gap: 32px;\n  justify-content: center;\n  max-width: 400px;\n  padding-bottom: 50px;\n}\n\n.icon {\n  margin: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"titleModal": `IHEPNOgU3pHRKTzyx23w`,
	"contentModal": `KGkFHMRfB2oJcbdeUu6A`,
	"iconModal": `g32JDkwBCKhjg1cnPJjP`,
	"modal": `N6vdUZCXzNFVvBhD_urQ`,
	"icon": `HhdvlRP5ir8m3t10_w8H`
};
export default ___CSS_LOADER_EXPORT___;

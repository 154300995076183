import { HeaderModal } from "@Components/ui";

const errorWhenDeleting = (setShowModal, setModalProps, errorTitle, errorMsg) => {
  setShowModal(true);

  setModalProps({
    showButtons: {
      showButtonClose: true,
    },
    children: <HeaderModal title={errorTitle} content={errorMsg} />,
    actionButtonClose: () => setShowModal(false),
  });
};

export default errorWhenDeleting;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cdOPRMMIMSykWbS7n4x7 {
  color: #45348e;
  font-family: "Mulish", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.2px;
  display: flex;
  width: 100%;
  gap: 8px;
}

@media screen and (max-width: 1200px) {
  .cdOPRMMIMSykWbS7n4x7 {
    flex-direction: column;
    gap: 4px;
    justify-content: space-between;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/atoms/RucRowItem/components/RucItem/components/RucName/style/rucName.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,iCAAiC;EACjC,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,qBAAqB;EACrB,aAAa;EACb,WAAW;EACX,QAAQ;AACV;;AAEA;EACE;IACE,sBAAsB;IACtB,QAAQ;IACR,8BAA8B;EAChC;AACF","sourcesContent":[".itemsRucs__id {\n  color: #45348e;\n  font-family: \"Mulish\", sans-serif;\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: normal;\n  letter-spacing: 0.2px;\n  display: flex;\n  width: 100%;\n  gap: 8px;\n}\n\n@media screen and (max-width: 1200px) {\n  .itemsRucs__id {\n    flex-direction: column;\n    gap: 4px;\n    justify-content: space-between;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"itemsRucs__id": `cdOPRMMIMSykWbS7n4x7`
};
export default ___CSS_LOADER_EXPORT___;

//@ts-ignore
import { HeaderNavigation } from "@viuti/recursos";
import { useNavigate } from "react-router-dom";
import React from "react";
//@ts-ignore
import styled from "./style/headerNavegation.module.css";

interface IHeaderNavegationBuilderProps {
  headerProps: any; //TODO: REMOVE ANY
  otherProps?: any;
}

const HeaderNavegationBuilder: React.SFC<IHeaderNavegationBuilderProps> = (props) => {
  const navigate = useNavigate();
  const { headerProps, otherProps } = props;

  return (
    <span id="billing-rucList-resources-headerNavegation">
      {otherProps && <HeaderNavigation {...headerProps(otherProps, navigate)} />}
      {!otherProps && <HeaderNavigation {...headerProps(navigate)} />}
    </span>
  );
};

export default HeaderNavegationBuilder;

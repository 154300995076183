//@ts-ignore
import { NoInfoToShow } from "@viuti/recursos";
import React from "react";
//@ts-ignore
import styled from "./style/isNotRucsToShow.module.css";

const NO_INFO_TO_SHOW_TITLE = "No hay RUC autorizado";
const NO_INFO_TO_SHOW_MESSAGE = "Agrega un RUC te guiamos en el proceso";

const IsNotRucsToShow: React.SFC = () => {
  return (
    <section className={styled.noInfoToShow}>
      <NoInfoToShow title={NO_INFO_TO_SHOW_TITLE} message={NO_INFO_TO_SHOW_MESSAGE} />
    </section>
  );
};

export default IsNotRucsToShow;

import { headerProps } from "@Models/consts/newRucs.const";
import { FileUploader, HeaderNavegationBuilder } from "@Components/index";
import { IRuc } from "@Models/interfaces/ruc";
//@ts-ignore
import { ModalAcceptCancel, Icon } from "@viuti/recursos";
import { useState } from "react";
import FieldsWrapper from "./components/FieldsWrapper/FieldsWrapper";
import useDisabledToContinue from "./hook/useDisabledToContinue";
//@ts-ignore
import styled from "./style/newRucsPage.module.css";
import { UserInfoIcon } from "@Models/consts/icons";

const intitRucData: IRuc = {
  ruc: null,
  businessName: null,
  expirationDate: null,
  claveCertificado: null,
  file: null,
};

const newRucPage = () => {
  const [showModalError, setShowModalError] = useState();
  const [modalProps, setModalProps] = useState(null);
  const [rucData, setRucData] = useState(intitRucData);
  const [file, setFile] = useState(null);
  const [logo, setLogo] = useState(null);
  const continueBttnProps = useDisabledToContinue({
    rucData,
    setRucData,
    file,
    setShowModal: setShowModalError,
    setModalProps: setModalProps,
    logo,
  });

  return (
    <div id="viuti-front-mainContent">
      {showModalError && <ModalAcceptCancel {...modalProps} />}
      <HeaderNavegationBuilder headerProps={headerProps} otherProps={continueBttnProps} />
      <div className={styled.container}>
        <p className={styled.newRucsPage__p}>
          Para obtener un Certificado Digital sigue las instrucciones que te damos{" "}
          <a className={styled.newRucsPage__a} href="https://cpe.sunat.gob.pe/certificado-digital" target="_blank">
            aquí
          </a>
          . Una vez realizados todos los pasos, podrás usar ese RUC para facturación electrónica.
        </p>
        <div className={styled.infoBox}>
          <Icon path={UserInfoIcon} alt="" size={16} color={"#937cf4"} />
          <p>
            Recuerde que para realizar la facturación electronica, se debe dar primero de alta en el SUNAT, siguiendo las
            instrucciones que te damos{" "}
            <a
              className={styled.newRucsPage__a}
              href="https://viuti-archivos.s3.amazonaws.com/recursos/NubefactAltaOSEManual"
              target="_blank"
            >
              aquí
            </a>
            .
          </p>
        </div>
        <FieldsWrapper rucData={rucData} setRucData={setRucData} />
        <FileUploader file={file} setFile={setFile} accept={[".p12", ".pfx"]} />
        <FileUploader
          topInformationMsg="Subir logo de facturación"
          file={logo}
          setFile={setLogo}
          accept={[".png", ".jpg", ".jpeg"]}
        />
      </div>
    </div>
  );
};

export default newRucPage;

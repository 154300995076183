import React, { useEffect } from "react";
//@ts-ignore
import { ButtonWithIcon } from "@viuti/recursos";
import { DeleteIcon, EditIcon } from "@Models/consts/icons";
import { BILLING_ROUTE } from "@Models/consts/routes";
import { useNavigate } from "react-router-dom";
import deleteRucAdapter from "@Adapter/deleteRucAdapter/deleteRucAdapter";
import useUserConfirmation from "./hook/useUserConfirmation";
import errorWhenDeleting from "@Adapter/deleteRucAdapter/errorWhenDeleting";
//@ts-ignore
import style from "./style/actions.module.css";

interface IRucActionsProps {
  idCertificate: number;
  refreshRucsAction: any;
  setModalProps: any;
  setShowModal: any;
  setIsDeleting: any;
  index: number;
}

const RucActions: React.SFC<IRucActionsProps> = (props) => {
  const { idCertificate, setShowModal, setModalProps, setIsDeleting, refreshRucsAction } = props;
  const [userConfirmation, userNeedToConfirmDelete] = useUserConfirmation(setShowModal, setModalProps);
  const navigate = useNavigate();

  const handleClick = () => userNeedToConfirmDelete();

  const useDeleteAdapter = async () => {
    try {
      await deleteRucAdapter({ ...props });
      refreshRucsAction();
    } catch (error) {
      setIsDeleting(false);
      errorWhenDeleting(
        setShowModal,
        setModalProps,
        "Error al intentar eliminar",
        "Ha ocurrido un error inesperado al intentar eliminar el RUC. Por favor vuelve a intentarlo mas tarde."
      );
    }
  };

  useEffect(() => {
    if (userConfirmation === null) return;
    if (userConfirmation === false) return;
    useDeleteAdapter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userConfirmation]);

  return (
    <div className={style.actions}>
      <ButtonWithIcon
        id={"billing-rucList-item-" + props.index + "-button-delete"}
        iconSvg={DeleteIcon}
        handleClick={() => handleClick()}
        iconColor={"#C66464"}
        bgColor={"#fde6e6"}
      />
      <ButtonWithIcon
        id={"billing-rucList-item-" + props.index + "-button-edit"}
        iconSvg={EditIcon}
        handleClick={() => navigate(`${BILLING_ROUTE.editRucs}/${idCertificate}`)}
        iconColor={"#423186"}
        bgColor={"#dad1f4"}
      />
    </div>
  );
};

export default RucActions;

//@ts-ignore
import { ModalAcceptCancel, SlideNotification } from "@viuti/recursos";
import getIdUrlPath from "./hook/getIdUrlPath";
import { useEffect, useState } from "react";
import { FileUploader, Loading } from "@Components/ui";
import FieldsWrapper from "./components/fieldWrapper/FieldWrapper";
import HeaderNavegationBuilder from "@Components/builder/HeaderNavegation/HeaderNavegation";
import { headerProps, headerPropsCertificate } from "@Models/consts/editRucs.const";
//@ts-ignore
import useShowBigPopUp from "./hook/useShowBigPopUp";
import { getCertificateByIdAdapter } from "@Adapter/editRucAdapter/getCertificateById.adapter";
//@ts-ignore
import styled from "../newRuc/style/newRucsPage.module.css";

const newRucPage = () => {
  const certificateId = getIdUrlPath();
  const [dataRuc, setDataRuc] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [modalProps, setModalProps] = useState(null);
  const [toast, setToast] = useState(null);
  const [stateResponse, setStateResponse] = useState({
    message: "",
    status: 0,
  });

  const getCertificate = async () => {
    const certificate = await getCertificateByIdAdapter(certificateId, setStateResponse);
    setDataRuc(certificate);
  };
  useEffect(() => {
    getCertificate();
  }, []);

  if (dataRuc === null) return <Loading />;

  const updateCertificate = () => {
    useShowBigPopUp(setShowModal, setModalProps, setToast, setDataRuc, certificateId);
  };
  return (
    <div id="viuti-front-mainContent">
      {showModal && <ModalAcceptCancel {...modalProps} />}
      <HeaderNavegationBuilder headerProps={headerProps} />
      <div className={styled.container}>
        <FieldsWrapper rucData={dataRuc} />
        <HeaderNavegationBuilder headerProps={headerPropsCertificate} otherProps={updateCertificate} />
        <FileUploader
          file={true}
          isRemoveAvaliable={false}
          topInformation={false}
          fileMsg={dataRuc.businessName + " " + dataRuc.ruc}
        />
      </div>
      {toast ? <SlideNotification state={{ message: toast.message, status: toast.color }} clearStatus={() => {}} /> : null}
      <SlideNotification state={stateResponse} clearStatus={() => setStateResponse({ message: "", status: 0 })} />
    </div>
  );
};

export default newRucPage;
